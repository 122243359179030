import { InfrastructureTests } from "../app/intellek/InfrastructureTests/InfrastructureTests";
import { Components } from '../app/intellek/components/Components';
import { NotFound } from "../app/errors/notFound/NotFound";
import { createRef } from 'react';

const routes = [
    {
        path: 'infrastructure/tests',
        nodeRef: createRef(),
        element: <InfrastructureTests />
    },
    {
        path: 'components',
        element: <Components />
    },
    {
        path: '*',
        element: <NotFound></NotFound>
    }
];

export default routes;