import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAuthorizationState } from "./authorization.types";
import { authorizationService } from "./authorization.service";
import { AuthorizationPolicy } from "../../../viewModels/application/authorizationPolicy";
import PageStrings from "../../../viewModels/pageStrings/pageStrings";
import { ActionStatus, Result } from "../../config";
import { jwtDecode } from "jwt-decode";
import { GetUserSessionPersmissionsResponse } from "../../../viewModels/authentication/getUserSessionPersmissionsResponse";

const pageStrings = new PageStrings();

const initialState: IAuthorizationState = {
    token: null,
    policies: {},
    status: ActionStatus.idle,
    error: '',
    claims: {},
    userPermissions: {},
    securityProfileLevels: [],
    maxSecurityProfileLevel: 0,
    userVisibility: 0,
    parentUserSessionId: '',
    isAuthenticated: false,
    isAssumingIdentity: false,
    isInitialLoad: true,
    userDisplayName: '',
    userIdentifier: ''
}

export const getPolicies = createAsyncThunk('application/policies', async() =>{
    return await authorizationService.getPolicies();
});

export const getUserPermissions = createAsyncThunk('authorization/usersession/permissions', async() =>{
    return await authorizationService.getUserPermissions();
});

export const authorizationSlice = createSlice({
    name: 'authorization',
    initialState,
    reducers: {

        setAuthorizationToken: (state: IAuthorizationState, action: PayloadAction<string>) => {
            let token = action.payload;
            
            if (token){
                
                var claims = decodeToken(token);
                
                state.isAuthenticated = true;
                state.token = token;
                state.claims = claims;
                state.userDisplayName = claims['Intellek.Lms.UserDisplayName'];
                state.userIdentifier = claims['Intellek.Lms.UserIdentifier'];
            }
        },

        setAssumingIdentity: (state: IAuthorizationState, action: PayloadAction<string>) => {
            
            let token = action.payload;

            let parentUserSessionUid = '';

            if (token.length > 0) {
                var claims = decodeToken(token);

                parentUserSessionUid = claims['Intellek.Lms.ParentUserSessionUid']??'';
                console.log(parentUserSessionUid)
            }

            if (parentUserSessionUid.length > 0){

                state.isAssumingIdentity = true;
                state.parentUserSessionId = parentUserSessionUid;

            } else {
                state.isAssumingIdentity = false;
                state.parentUserSessionId = '';
            }
            
        },

        setUserPermissions: (state: IAuthorizationState, action: PayloadAction<{ [key: string]: string }>) => {
            if(action.payload){
                state.userPermissions = action.payload;
            }
        }
    },
    extraReducers: (builder) => {
        
        builder.addCase(getPolicies.pending, (state: IAuthorizationState) => {
            state.status = ActionStatus.loading;
        });

        builder.addCase(getPolicies.rejected, (state: IAuthorizationState) => {
            state.status = ActionStatus.failed;
            state.error = pageStrings.general.error_Unexpected;
        });

        builder.addCase(getPolicies.fulfilled, (state: IAuthorizationState, action: PayloadAction<{[id: string]: AuthorizationPolicy}>) => {
            state.status = ActionStatus.succeeded;
            state.policies = action.payload;
            state.isInitialLoad = false;
        });

        builder.addCase(getUserPermissions.pending, (state: IAuthorizationState) => {
            // state.status = ActionStatus.loading;
        });

        builder.addCase(getUserPermissions.rejected, (state: IAuthorizationState) => {
            // state.status = ActionStatus.failed;
            state.error = pageStrings.general.error_Unexpected;
        });

        builder.addCase(getUserPermissions.fulfilled, (state: IAuthorizationState, action: PayloadAction<Result<GetUserSessionPersmissionsResponse>>) => {
            // state.status = ActionStatus.succeeded;
            if(action.payload.value){
                state.userPermissions = action.payload.value.userSessionPermissions;
                state.maxSecurityProfileLevel = action.payload.value.maxSecurityProfileLevel;
                state.userVisibility = action.payload.value.userVisibility;
                state.securityProfileLevels = action.payload.value.securityProfileLevels;
            }
        });
    }
});

export const {
    setAuthorizationToken,
    setAssumingIdentity,    
    setUserPermissions,
} = authorizationSlice.actions;

export default authorizationSlice.reducer;

export function decodeToken(token: string) {
    return jwtDecode(token) as { [key: string]: string };
}