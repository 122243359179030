import { useLayoutEffect } from "react";
import { useAppDispatch } from "../../store"
import { changePageTitle, setCanAccessPolicy } from "../../store/slices/layout";
import { PolicyName } from "./useActionChecker";

interface IHookProperties{
    pageTitle?: string | undefined
    canAccessPolicy?: PolicyName | undefined
}

export function useLayout (props: IHookProperties){
    
    const dispatch = useAppDispatch();

    useLayoutEffect(() =>{

        if (props.canAccessPolicy){
            dispatch(setCanAccessPolicy(props.canAccessPolicy));
        }

        dispatch(changePageTitle(props.pageTitle??''));

    }, [dispatch, props]);
}