import { createComponent } from '@lit/react';
import { MdDialog } from '@material/web/dialog/dialog';
import React from 'react';

import {Text} from '../../text/Text';
import {Form, submitForm} from '../../form/Form';
import {IconButton} from '../iconButton/IconButton';

import styles from './Dialog.module.scss';
import { TextButton } from '../button/TextButton';
import { useWindowSize } from '../../../hooks';
import { WindowSizeType } from '../../../services/windowSize.service';

const DialogComponent = createComponent({
    tagName: 'md-dialog',
    elementClass: MdDialog,
    react: React
});

interface IComponentProperties {
    id: string,
    headline: string,
    children: React.ReactNode[] | React.ReactNode,
    confirmAction?: IAction,
    dismissAction?: IAction,
    className?: string
}

interface IAction {
    label: string, 
    type: 'submit' | 'button',
    action: Function
}

enum DialogType{
    basic,
    fullScreen
}

export function Dialog (props: IComponentProperties){
    const windowSizeType = useWindowSize();
    const dialogType :DialogType = windowSizeType === WindowSizeType.Compact ?  DialogType.fullScreen : DialogType.basic;

    const formId = `${props.id}-form`;
    const dialogTypeStyles = dialogType === DialogType.fullScreen ?  styles['is-fullScreen'] : '';

    function handleCloseClicked (){
        closeDialog(props.id);
    }

    function handleActionClicked (action: IAction){

        if (action.type === 'button'){

            performAction(action);

        } else if (action.type === 'submit'){

            submitForm(formId);

        }

    }

    function handleFormSubmitted (formData: any){
        if(props.confirmAction?.type === 'submit')
        {
            performAction(props.confirmAction, formData);
        }        
    }

    function performAction (action: IAction, formData?: any){
        
        let result = action.action(formData);
            
        if (result === undefined || result === true){
            
            closeDialog(props.id);
            
        }
    }
    
    return (
      <>
        <DialogComponent
          id={props.id}
          className={`${styles["Dialog"]} ${props.className} ${dialogTypeStyles}`}
        >          
          <div slot="headline" className={styles["Dialog-headline"]}>
            {dialogType === DialogType.basic && (
              <Text fontStyle="headline-small" value={props.headline}></Text>
            )}
            {dialogType === DialogType.fullScreen && (
              <>
                <span>
                  <IconButton
                    iconName="close"
                    ariaLabel="Close dialog"
                    tooltipText="close"
                    onClick={handleCloseClicked}
                  ></IconButton>
                </span>
                <span className={styles['Dialog-headlineTitle']}>
                    <Text fontStyle="title-large" value={props.headline}></Text>
                </span>
                {props.confirmAction && (
                  <TextButton
                    key={0}
                    buttonId={`${props.id}-button-${0}`}
                    type={props.confirmAction?.type}
                    value={props.confirmAction?.label}
                    onClick={() => handleActionClicked(props.confirmAction!)}
                  ></TextButton>
                )}
              </>
            )}
          </div>

          <Form
            formId={formId}
            slotName="content"
            method="dialog"
            onSubmit={handleFormSubmitted}
            hiddenSubmit={true}
            className={styles["Dialog-content"]}
          >
            {props.children}
          </Form>

          {dialogType !== DialogType.fullScreen &&
            <div slot="actions">
              { props.dismissAction && 
                <TextButton
                key={1}
                buttonId={`${props.id}-button-${1}`}
                type={props.dismissAction?.type!}
                value={props.dismissAction?.label!}
                onClick={() => handleCloseClicked}
                ></TextButton>
              }
              { props.confirmAction && 
                <TextButton
                      key={2}
                      buttonId={`${props.id}-button-${2}`}
                      type={props.confirmAction?.type!}
                      value={props.confirmAction?.label!}
                      onClick={() => handleActionClicked(props.confirmAction!)}
                ></TextButton>
              }
            </div>
          }
        </DialogComponent>
      </>
    );

}

export function openDialog(dialogId: string){
    let dialogElement = document.getElementById(dialogId) as MdDialog;

    if (dialogElement){
        dialogElement.show();
    }
}

export function closeDialog (dialogId: string){
    let dialogElement = document.getElementById(dialogId) as MdDialog;

    if (dialogElement){
        dialogElement.close();
    }
}