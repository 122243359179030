import React from 'react';
import { createComponent } from '@lit/react';

import { MdOutlinedSelect } from '@material/web/select/outlined-select';

import styles from './OutlinedSelect.module.scss';
import { Text } from '../../text/Text';

const OutlinedSelectComponent = createComponent({
    tagName: 'md-outlined-select',
    elementClass: MdOutlinedSelect,
    react: React
});

interface IComponentProperties {
    label: string
    required?: boolean | undefined
    supportingText?: string | undefined
    value?: string | undefined,
    children?: React.ReactNode[] | undefined,
}

export function OutlinedSelect (props: IComponentProperties){

    return (
        <div className={styles['OutlinedSelect-container']}>
            <OutlinedSelectComponent 
                className={styles['OutlinedSelect']}
                aria-label={props.label}
                required={props.required === true}
                supportingText={props.supportingText}
                value={props.value}
                >
                {props.children}
            </OutlinedSelectComponent>
        </div>
    );

}