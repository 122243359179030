import React from 'react';
import { createComponent } from '@lit/react';
import { MdElevation } from "@material/web/elevation/elevation";


const ElevationComponent = createComponent({
    tagName: 'md-elevation',
    elementClass: MdElevation,
    react: React
});

export function Elevation(){

    return (
        <div className="level3">
            <ElevationComponent></ElevationComponent>
        </div>
    );
}
