
import { FilledButton, OutlinedTextField, Form, Text } from '../../../common/components';
import styles from './createAccount.module.scss';

function handleSaveAuthenticationActivated (formData: any){
}

export function CreateAccount () {

    return (
        <Form onSubmit={handleSaveAuthenticationActivated} className={styles['CreateAccount']}>           
            <header>
                <h1 className={styles['CreateAccount-heading']}>
                    <Text fontStyle="headline-small" value={'Create Account'}></Text>
                </h1>
            </header>
            <div className={styles['CreateAccount-content']}>
                <div className={styles['CreateAccount-contentRow']}>
                    <div className={styles['CreateAccount-contentRowItem']}>
                        <OutlinedTextField name="createaccount_firstname" required
                        label={'First name'}  
                        placeholder={'First name'}                            
                        ></OutlinedTextField>
                    </div>
                    <div className={styles['CreateAccount-contentRowItem']}>
                        <OutlinedTextField name="createaccount_lastname" required
                            label={'Last name'}  
                            placeholder={'Last name'}>
                        </OutlinedTextField>
                    </div>
                </div>
                <div className={styles['CreateAccount-contentRow']}>
                    <div className={styles['CreateAccount-contentRowItem']}>
                        <OutlinedTextField name="createaccount_emailAddress" required
                            label={'Email address'}  
                            type='email'
                            placeholder={'Email address'}>
                        </OutlinedTextField>
                    </div>
                    <div className={styles['CreateAccount-contentRowItem']}>
                        <OutlinedTextField name="createaccount_username"
                            label={'Username'}  
                            placeholder={'Username'}>
                        </OutlinedTextField>
                    </div>
                </div>
                <div className={styles['CreateAccount-contentRow']}>
                    <div className={styles['CreateAccount-contentRowItem']}>
                        <OutlinedTextField name="createaccount_password" required
                            label={'Password'}  
                            type='password'
                            placeholder={'Password'}>
                        </OutlinedTextField>
                    </div>
                    <div className={styles['CreateAccount-contentRowItem']}>
                        <OutlinedTextField name="createaccount_confirmPassword" required
                            type='password'
                            label={'Confirm password'}  
                            placeholder={'Confirm password'}>
                        </OutlinedTextField>
                    </div>
                </div>
                <div className={styles['CreateAccount-contentRow']}>
                    <div className={styles['CreateAccount-contentRowItem']}>
                        <OutlinedTextField name="createaccount_telephone" 
                            label={'Telephone'}  
                            type='tel'
                            placeholder={'Telephone'}>
                        </OutlinedTextField>
                    </div>
                    <div className={styles['CreateAccount-contentRowItem']}>
                        <OutlinedTextField name="createaccount_location" 
                            label={'Location'}  
                            placeholder={'Location'}>
                        </OutlinedTextField>
                    </div>
                </div>
                <div className={styles['CreateAccount-contentRow']}>
                    <div className={styles['CreateAccount-contentRowItem']}>
                        <OutlinedTextField name="createaccount_title" 
                            label={'Title'}  
                            placeholder={'Title'}>
                        </OutlinedTextField>
                    </div>
                    <div className={styles['CreateAccount-contentRowItem']}>
                        <OutlinedTextField name="createaccount_department" 
                            label={'Department'}  
                            placeholder={'Department'}>
                        </OutlinedTextField>  
                    </div>
                </div>
                <div className={styles['CreateAccount-contentRow']}>
                    <div className={styles['CreateAccount-contentRowItem']}>
                        <OutlinedTextField name="createaccount_practiceArea" 
                            label={'Practice Area'}  
                            placeholder={'Practice Area'}>
                        </OutlinedTextField>
                    </div>
                    <div className={styles['CreateAccount-contentRowItem']}>
                        <OutlinedTextField name="createaccount_profile" 
                            label={'Profile'}  
                            placeholder={'Profile'}>
                        </OutlinedTextField>
                    </div>
                </div>
                <div className={styles['CreateAccount-contentButton']}>

                    <FilledButton type='submit' value='Create'></FilledButton>
                </div>
            </div>
        </Form>
    );
}