import i18n  from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import urlService from '../common/services/url.service';

export async function initI18n (fnCallback: Function){
    await i18n
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init<HttpBackendOptions>({
        debug: false, // Enable this option only in Dev mode if required
        fallbackLng: "en-US",
        backend: {
            loadPath: `${urlService.buildApiUrl('application/pagestrings/')}{{lng}}`,
            customHeaders: {
                'LMS_Origin_Url': urlService.buildAppUrl('')
            },
            crossDomain: true,
        },
        load: 'currentOnly',
        // preload: true,
        nonExplicitSupportedLngs: true,
        supportedLngs: [
            "en-US",
            "en-GB",
            "en", 
            "fr-CA",
            "fr",
            "es",
            "pt",
            "de",
        ],
        react: {
            useSuspense: false
        }
    }, (err) => {
        fnCallback(err);
    });
}