import { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { windowSizeChanged } from "../store/slices/layout/layout.slice";
import Routes from "../routes/app.routes";
import { useAppDispatch } from "../store";

const router = createBrowserRouter(Routes);

const App = () => {

    const dispatch = useAppDispatch();

    useEffect(() => {
        document.title = 'Intellek LMS';

        handleWindowSizeChanged();

        window.addEventListener("resize", handleWindowSizeChanged);

        // Cleanup on component unmount
        return () => {
            window.removeEventListener("resize", handleWindowSizeChanged);
        };
    }, []);  // Run this once on mount

    function handleWindowSizeChanged() {
        dispatch(
            windowSizeChanged({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        );
    }

    return (
            <RouterProvider router={router} />
    );
};

export default App;
