import React from 'react';
import styles from './Image.module.scss';

interface IComponentProperties {
    alternativeText: string;
    title?: string;
    aspectRatio: AspectRatio;
    className?: string;
    imageSource: string;
    orientation: Orientation;
}

type AspectRatio = 'goldenRatio';

type Orientation = 'landscape' | 'portrait';

export function Image(props: IComponentProperties) {
    const aspectRatioStyle = styles[`Image--${props.aspectRatio}`];
    const orientationStyle = styles[`is-${props.orientation}`];

    const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = '/assets/logo.png';
    };

    return (
        <img
            src={props.imageSource}
            alt={props.alternativeText}
            title={props.title}
            className={`${props.className} ${aspectRatioStyle} ${orientationStyle}`}
            onError={handleImageError}
        />
    );
}