import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import { ElevatedCard, Image } from '../../components';

import { RootState, useAppSelector } from '../../../store';
import { useWindowSize } from '../../hooks';
import { WindowSizeType } from '../../services/windowSize.service';

import styles from './AuthenticationLayout.module.scss';


export default function AuthenticationLayout() {

    const configuration = useAppSelector((state: RootState) => state.configuration);
    const location = useLocation();
    const windowSizeType = useWindowSize();


    return (
        <>
            {windowSizeType === WindowSizeType.Compact &&

                <main className={styles['AuthenticationLayout']}>
                    <div className={`${styles['AuthenticationLayout-cardContent']}`}>
                        <SwitchTransition>
                            <CSSTransition key={location.pathname} timeout={0} classNames={'page'}>
                                <Outlet />
                            </CSSTransition>
                        </SwitchTransition>
                    </div>
                </main>
            } 
            {windowSizeType !== WindowSizeType.Compact && 
                <main className={styles['AuthenticationLayout']}>
                    <Image alternativeText='Company Logo'
                        title={configuration.branding.companyName}
                        imageSource={configuration.branding.companyLogo ?? '@/assets/images/logo.png'}
                        className={styles['Components-image']}
                        aspectRatio={"goldenRatio"}
                        orientation="landscape"/>
                    <ElevatedCard className={`${styles['AuthenticationLayout-card']}`}>
                        <div className={`${styles['AuthenticationLayout-cardContent']}`}>
                            <SwitchTransition>
                                <CSSTransition key={location.pathname} timeout={0} classNames={'page'}>
                                    <Outlet />
                                </CSSTransition>
                            </SwitchTransition>
                        </div>
                    </ElevatedCard>
                </main>
            }
        </>
    );

}