
import { usePageStrings } from "../../../../common/hooks";
import { FilledButton, OutlinedTextField, Form, Link, Text} from '../../../../common/components'

import styles from './SignInUserAndPassword.module.scss';

interface IComponentProperties {
    isSubmitting: boolean,
    onSubmit: Function,
};

export default function SignInUserAndPassword (props: IComponentProperties){
    
    const pageStrings = usePageStrings();    
    const formId = "intellek-lms-signin-form";
    return (
        <>
        
            <Form onSubmit={props.onSubmit} formId={formId} hiddenSubmit>

                <OutlinedTextField name="username" required
                    label={pageStrings.signInPage.formItem_userName_label}  
                    placeholder={pageStrings.signInPage.formItem_userName_placeholder}
                    formid={formId}
                    
                    >
                </OutlinedTextField>

                <OutlinedTextField name="password" type="password" required
                    label={pageStrings.signInPage.formItem_password_label}  
                    placeholder={pageStrings.signInPage.formItem_password_placeholder}
                    formid={formId}
                    >
                </OutlinedTextField>
                
                <p>
                    <Link to={'/authentication/credentials/forgot'} 
                        text={pageStrings.signInPage.link_forgotCredentials_label}>
                    </Link>
                </p>

                <FilledButton type="submit" 
                    value={pageStrings.signInPage.button_signIn_label}
                    disabled={props.isSubmitting}>
                </FilledButton>

                <p className={styles['SignInUserAndPassword-createAccount']}>

                    <Text fontStyle='body-medium' value={pageStrings.signInPage.text_doNotHaveAccount}></Text>

                    <Link to={'/authentication/account/create'} 
                        text={pageStrings.signInPage.link_createAccount_label}>
                    </Link>

                </p>

            </Form>
        </>
    );
}