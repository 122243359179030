import { ProfileActions } from './profileActions/ProfileActions';
import { ProfileInfo } from './profileInfo/ProfileInfo';
import styles from './MyProfile.module.scss';
import { UserProfile } from '../../../viewModels/user/userProfile';
import { useEffect, useState } from 'react';
import { userAccountService } from '../../../store/slices/profile/useraccount.service';
import { useLayout, usePageStrings } from '../../../common/hooks';
import { UserBasic } from '../../../viewModels/user/userBasic';

export function MyProfile (){

    const [userAccount, setUserAccount] = useState<UserProfile>({} as UserProfile);

    const pageStrings = usePageStrings();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {

        

        userAccountService.getUserProfile().then((profile) => {
            setUserAccount(profile.value);
            setLoading(false);
        }).catch((error) => {
            setError(error.message);
            setLoading(false);
        });

         

    }, []);

    useLayout({
        pageTitle: pageStrings.myProfilePage.pageTitle
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className={styles['MyProfile']}>
            <ProfileInfo userAccount={userAccount}></ProfileInfo>
            <ProfileActions></ProfileActions>
        </div>
    );

}
