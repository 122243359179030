import { useEffect } from "react";

import { initI18n } from "../../../config/i18n";
import { ActionStatus, useAppDispatch, useAppSelector } from "../../../store";

import AppLayout from "../appLayout/AppLayout";
import AuthenticationLayout from "../authenticationLayout/AuthenticationLayout";
import { Loading } from "../../../app/loading/Loading";
import { ModuleError } from "../../../app/errors/moduleError/ModuleError";
import { configurationLoaded, setErrorMessage, startInitialLoad, translationFileLoaded } from "../../../store/slices/layout";
import { usePageStrings } from "../../hooks";
import { configurationHub, getConfiguration, getConfigurationBasic } from "../../../store/slices/configuration";

interface IComponentProperties{
    layoutType: 'external' | 'application'
}

export function MainLayout (props: IComponentProperties) {

    const dispatch = useAppDispatch();

    const configuration = useAppSelector((state) => state.configuration);
    const layout = useAppSelector((state) => state.layout);
    const pageStrings = usePageStrings();

    useEffect(() => {

        if (layout.status === ActionStatus.idle){

            dispatch(startInitialLoad());

            initI18n((err: any) => {
                if (err) {
                    dispatch(setErrorMessage(pageStrings.general.error_Unexpected));
                } else {
                    dispatch(translationFileLoaded())
                }

            });
        }

        if (configuration.status === ActionStatus.idle){

            dispatch(props.layoutType === 'application'? 
                getConfiguration():
                getConfigurationBasic());
            
            configurationHub.start();

        } else if (configuration.status === ActionStatus.failed){
            dispatch(setErrorMessage(pageStrings.general.error_Unexpected));
        } else if (configuration.status === ActionStatus.succeeded){
            dispatch(configurationLoaded());
        }    

    }, [configuration, dispatch, layout, pageStrings]);
   
    if (layout.status === ActionStatus.failed){
        return (<ModuleError message={layout.error}></ModuleError>);
    }

    if (layout.status === ActionStatus.loading){
        return (<Loading></Loading>);
    }

    return (
        <>        

            {props.layoutType === 'application' && 
                <AppLayout></AppLayout>
            }

            {props.layoutType === 'external' && 
                <AuthenticationLayout></AuthenticationLayout>
            }

        </>
    );

}
