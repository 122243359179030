import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { usePageStrings } from "../../../common/hooks";
import { sessionStorageService } from "../../../common/services/sessionStorage.service";
import { Action, ActionStatus, Result, RootState, useAppDispatch, useAppSelector } from "../../../store";
import { appLogService } from "../../../store/slices/appLog";
import { authenticationService } from "../../../store/slices/authentication";
import { setAuthorizationToken, setUserPermissions } from "../../../store/slices/authorization";
import { BannerMessage, Divider, FilledTonalButton, Text } from "../../../common/components";
import SignInUserAndPassword from "./signInUserAndPassword/SignInUserAndPassword";
import styles from "./SignIn.module.scss";
import urlService from "../../../common/services/url.service";
import { SignInWithPasswordResponse } from "../../../viewModels/authentication/signInWithPasswordResponse";
import { lms3Service } from "../../../store/slices/lms3";
import { createMsalInstance, loginRequest } from "../../../authAzureConfig";

export function SignIn() {
    const dispatch = useAppDispatch();
    const pageStrings = usePageStrings();
    const [signInAction, setSignInAction] = useState(Action.idle());
    const [searchParams] = useSearchParams();

    const configurationAuthentication = useAppSelector((state: RootState) => state.configuration.authentication);

    const isUserAndPasswordEnabled = configurationAuthentication.usernameAndPassword_isEnabled === true;
    const isSsoEnabled =
        configurationAuthentication.adfs_IsEnabled === true ||
        configurationAuthentication.azure_IsEnabled === true ||
        configurationAuthentication.okta_isEnabled === true;

    const refUrl = searchParams.get("refUrl") ?? urlService.buildLegacyAppUrl("");

    useEffect(() => {

        // Clear all cookies
        document.cookie.split(";").forEach((c) => {
            document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
        });

        // Clear sessionStorage and localStorage
        sessionStorage.clear();
        localStorage.clear();

        if(signInAction.status === ActionStatus.idle){
            if (!isUserAndPasswordEnabled) {
                handleSingleSignOn();
            }
        }
    }, [isUserAndPasswordEnabled]);

    const handleSingleSignOn = async () => {

        setSignInAction(Action.loading());
      
        if(configurationAuthentication.azure_IsEnabled){           
            await loginWithAzureSSO();
        }
    };

    const loginWithAzureSSO = async () => { 

        setSignInAction(Action.loading());

        await createMsalInstance({
            azure_ClientId: configurationAuthentication.azure_ClientId,
            azure_TenantId: configurationAuthentication.azure_TenantId,
            azure_RedirectUri: configurationAuthentication.azure_RedirectUri,
        }).then((instance) => {
            instance
            .loginPopup(loginRequest)
            .then((response) => {
                const token = response.accessToken;

            authenticationService
                    .azureSignIn({ token: token })
                    .then((result: Result<SignInWithPasswordResponse>) => {
                        if (result.isSuccess) {
                            dispatch(setAuthorizationToken(result.value.token));
                            dispatch(setUserPermissions(result.value.permissions));
                            sessionStorageService.setAuthorizationToken(result.value.token);
                            sessionStorageService.setUserPermissions(result.value.permissions);

                            lms3Service.setLms4Cookie()
                                .then(() => {
                                    window.location.assign(refUrl);
                                })
                                .catch((error) => {
                                    appLogService.fatal("SignIn.tsx", "setLms4Cookie", "SignIn", JSON.stringify(error) ?? "");
                                });
                        }
                    })
                    .catch((reason) => {
                        appLogService.fatal("SignIn.tsx", "azureSignIn", "SignIn", JSON.stringify(reason) ?? "");
                    });
            })
            .catch((error: any) => {
                appLogService.fatal("SignIn.tsx", "handleSingleSignOn", "SSO", JSON.stringify(error) ?? "");
            });
        });
    };

    function handleSignIn(formData: SignInUsernameAndPasswordFormData) {
        setSignInAction(Action.loading());

        authenticationService.signIn({
            password: formData.password,
            username: formData.username,
            timeZoneInfoID: Intl.DateTimeFormat().resolvedOptions().timeZone
        }).then((result: Result<SignInWithPasswordResponse>) => {

            if (result.isSuccess){
                dispatch(setAuthorizationToken(result.value.token));
                dispatch(setUserPermissions(result.value.permissions));                     
                sessionStorageService.setAuthorizationToken(result.value.token);
                sessionStorageService.setUserPermissions(result.value.permissions);                
                
                lms3Service.setLms4Cookie().then( ()=>{
                    setSignInAction(Action.succeeded());
                    window.location.assign(refUrl);
                }).catch(error => {
                    setSignInAction(Action.failed(pageStrings.general.error_Unexpected));
                    appLogService.fatal('SignIn.tsx', 'handleSignIn', 'signedIn', JSON.stringify(error)??'');
                });

            }
            else {
                setSignInAction(Action.failed(result.errorMessage));
            }

        }).catch((reason) => {
            setSignInAction(Action.failed(pageStrings.general.error_Unexpected));
            appLogService.fatal('SignIn.tsx', 'handleSignIn', 'SignIn', JSON.stringify(reason)??'');
        });
    }    

    return (
        <div className={styles["SignIn"]}>
            <header>
                <h1 className={styles["SignIn-heading1"]}>
                    <Text fontStyle="headline-small" value={pageStrings.signInPage.heading_welcome}></Text>
                </h1>
            </header>

            {signInAction.message && signInAction.status === ActionStatus.failed && (
                <div className={styles["SignIn-errorMessage"]}>
                    <BannerMessage messageType="error" message={signInAction.message}></BannerMessage>
                </div>
            )}

            <div className={styles["SignIn-content"]}>
                {isUserAndPasswordEnabled && (
                    <SignInUserAndPassword
                        onSubmit={handleSignIn}
                        isSubmitting={signInAction.status === ActionStatus.loading || signInAction.status === ActionStatus.succeeded}
                    ></SignInUserAndPassword>
                )}

                {(isUserAndPasswordEnabled && isSsoEnabled) && <Divider></Divider>}

                {isSsoEnabled && (
                    <FilledTonalButton type={"button"} value={pageStrings.signInPage.button_singleSignOn_label} onClick={handleSingleSignOn}></FilledTonalButton>
                )}
            </div>
        </div>
    );
}

type SignInUsernameAndPasswordFormData = {
    username: string;
    password: string;
};