import { useState } from 'react';
import { Text, FilledTonalButton } from '../../../../../common/components';
import styles from './Password.module.scss';
import { Dialog, openDialog } from '../../../../../common/components/material/dialog/Dialog';
import {ChangePassword} from '../../../changePassword/ChangePassword';
import { Action } from '../../../../../store';
import usePageStrings from '../../../../../common/hooks/usePageStrings';
import { appLogService } from '../../../../../store/slices/appLog';
import { authenticationService } from '../../../../../store/slices/authentication';

export function Password() {
    const pageStrings = usePageStrings();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [changePasswordAction, setChangePasswordAction] = useState(Action.idle());
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordChecks, setPasswordChecks] = useState({
        length: false,
        upper: false,
        lower: false,
        number: false,
        special: false,
    });

    const handleNewPasswordChange = (e:any) => {
        const value = e.target.value;
        setNewPassword(value);
        setPasswordChecks({
            length: value.length >= 12,
            upper: /[A-Z]/.test(value),
            lower: /[a-z]/.test(value),
            number: /[0-9]/.test(value),
            special: /[!@#$%^&*]/.test(value),
        });
    };

    const handleConfirmNewPasswordChange = (e:any) => {
        setConfirmNewPassword(e.target.value);
    };

    const handleChangePassword = () => {
        setIsDialogOpen(true);
        openDialog('change-password-dialog');
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        //closeDialog('change-password-dialog');
    };

    const handleSubmit = async (e:any) => {
        //e.preventDefault();
        if (newPassword !== confirmNewPassword) {
            setErrorMessage("Passwords do not match");
            console.log('Passwords do not match');
            setIsDialogOpen(true);
            return false;
        }

        try {
            const result = await authenticationService.changePassword({
                oldPassword: currentPassword,
                newPassword: newPassword,
            });

            if (result.isSuccess) {
                setChangePasswordAction(Action.succeeded());
                handleDialogClose();
            } else {
                setChangePasswordAction(Action.failed(result.errorMessage));
                setErrorMessage(result.errorMessage);
            }
        } catch (reason: any) {
            setErrorMessage(reason.message || 'Failed to change password');
            setChangePasswordAction(Action.failed(pageStrings.general.error_Unexpected));
            appLogService.fatal('Password.tsx', 'handleSubmit', 'Send', reason);
        }
    };

    return (
        <section className={styles['Password']}>
            <h2><Text value={pageStrings.changePassword.heading_password} fontStyle='title-medium'></Text></h2>
            <p><Text value={pageStrings.changePassword.text_neverRevealPassword} fontStyle='body-medium'></Text></p>
            <div>
                <FilledTonalButton type='button' value='Change' onClick={handleChangePassword}></FilledTonalButton>
            </div>

            {isDialogOpen && (
                <Dialog 
                    id="change-password-dialog" 
                    headline="Change Password" 
                    confirmAction={{ label: pageStrings.changePassword.button_save_label, type: 'submit', action: handleSubmit }}
                    dismissAction={{ label: pageStrings.changePassword.button_cancel_label, type: 'button', action: handleDialogClose }}
                >
                    <ChangePassword
                        currentPassword={currentPassword}
                        newPassword={newPassword}
                        confirmNewPassword={confirmNewPassword}
                        errorMessage={errorMessage}
                        onCurrentPasswordChange={(e:any) => setCurrentPassword(e.target.value)}
                        onNewPasswordChange={handleNewPasswordChange}
                        onConfirmNewPasswordChange={handleConfirmNewPasswordChange}
                        onSubmit={handleSubmit}
                    />
                </Dialog>
            )}
        </section>
    );
}
