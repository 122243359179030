import { combineReducers } from "redux";
import authenticationReducer from "./slices/authentication/authentication.slice";
import authorizationReducer from './slices/authorization/authorization.slice';
import layoutReducer from "./slices/layout/layout.slice";
import configurationReducer from "./slices/configuration/configuration.slice";
import snackbarReducer from "./slices/snackbar/snackbar.slice";


export default combineReducers({
    authentication: authenticationReducer,
    authorization: authorizationReducer,
    configuration: configurationReducer,
    layout: layoutReducer,
    snackbar:snackbarReducer,

});