import React from 'react';
import { createComponent } from '@lit/react';

import { MdFilledSelect } from '@material/web/select/filled-select';

import styles from './FilledSelect.module.scss';
import { Text } from '../../text/Text';

const FilledSelectComponent = createComponent({
    tagName: 'md-filled-select',
    elementClass: MdFilledSelect,
    react: React
});

interface IComponentProperties {
    label: string
    required?: boolean | undefined
    supportingText?: string | undefined
    value?: string | undefined,
    children?: React.ReactNode[] | undefined,
}

export function FilledSelect (props: IComponentProperties){

    return (
        <div className={styles['FilledSelect-container']}>
            <label>
                <Text fontStyle='label-medium' value={props.label}></Text>
            </label>
            <FilledSelectComponent 
                className={styles['FilledSelect']}
                aria-label={props.label}
                required={props.required === true}
                supportingText={props.supportingText}
                value={props.value}
                >
                {props.children}
            </FilledSelectComponent>
        </div>
    );

}