import { Divider, Text } from '../../../../common/components';
import styles from './ProfileInfo.module.scss';
import { UserProfile } from '../../../../viewModels/user/userProfile';
import { usePageStrings } from '../../../../common/hooks';

interface IComponentProperties {
    userAccount: UserProfile;
}

export function ProfileInfo({ userAccount }: IComponentProperties) {
    
    const pageStrings = usePageStrings();

    function renderInfoItem(infoLabel: string, infoValue: string) {
        return (
            <div className={styles['ProfileInfo-dataItem']}>
                <dt>
                    <Text value={infoLabel} fontStyle='label-medium'></Text>
                </dt>
                <dd>
                    <Text value={infoValue} fontStyle='body-medium'></Text>
                </dd>
            </div>
        );
    }

    return (
        <section className={styles['ProfileInfo']}>
            <h2><Text value='System Administrator' fontStyle='title-medium'></Text></h2>
    
            <dl>
                {renderInfoItem(pageStrings.myProfilePage.text_username, userAccount.username ?? '')}
                {renderInfoItem(pageStrings.myProfilePage.text_fullName, userAccount.fullName ?? '')}
                {renderInfoItem(pageStrings.myProfilePage.text_emailAddress, userAccount.email ?? '')}
                {renderInfoItem(pageStrings.myProfilePage.text_phoneNumber, userAccount.phoneNumber ?? '')}
                {renderInfoItem(pageStrings.myProfilePage.text_location, userAccount.location ?? '')}
            </dl>
    
            <Divider></Divider>
    
            <dl>
                {renderInfoItem(pageStrings.myProfilePage.text_title, userAccount.title ?? '')}
                {renderInfoItem(pageStrings.myProfilePage.text_department, userAccount.department ?? '')}
                {renderInfoItem(pageStrings.myProfilePage.text_practiceArea, userAccount.practiceArea ?? '')}
                {renderInfoItem(pageStrings.myProfilePage.text_profile, userAccount.profile ?? '')}
            </dl>
        </section>
    );    
}
