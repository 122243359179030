import React, { useState } from 'react';
import { OutlinedTextField } from '../../../common/components/material';
import styles from './ChangePassword.module.scss';
import { BannerMessage } from '../../../common/components';
import { PasswordRule } from '../../../common/features/password/PasswordRule';

interface IComponentProperties {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  errorMessage: string;
  onCurrentPasswordChange: Function,
  onNewPasswordChange: Function,
  onConfirmNewPasswordChange: Function,
  onSubmit: Function
}

export function ChangePassword (props: IComponentProperties) {

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const handlePasswordValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);   
  };

  const handleCurrentPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCurrentPassword(value);   
  };

  const handleConfirmNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setConfirmNewPassword(value);   
  };


  return (
    <div className={styles['ChangePassword']}>

        {props.errorMessage && (
          <div className={styles['ChangePassword-errorMessage']}>
            <BannerMessage messageType="error" message={props.errorMessage}></BannerMessage>
          </div>
        )}

        <div className={styles['ChangePassword-content']}>
          <OutlinedTextField
            label="Current Password"
            name="currentPassword"
            placeholder="Current password"
            required
            type="password"
            value={props.currentPassword}
            onChange={handleCurrentPasswordChange}
          />

          <OutlinedTextField
            label="New Password"
            name="newPassword"
            placeholder="New password"
            required
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />

          <OutlinedTextField
            label="Confirm New Password"
            name="confirmNewPassword"
            placeholder="Confirm new password"
            required
            type="password"
            value={props.confirmNewPassword}
            onChange={handleConfirmNewPasswordChange}
          />
         
          <ul>
            <li>              
              <PasswordRule
                password={password}
                label= "At least 12 characters"
                validation= ".{12,}"
              ></PasswordRule>                        
            </li>
            <li>
              <PasswordRule
                  password={password}
                  label= "Upper case letter required"
                  validation= "[A-Z]"
              ></PasswordRule>
            </li>
            <li>
              <PasswordRule
                  password={password}
                  label= "Lower case letter required"
                  validation= "[a-z]"
              ></PasswordRule>
            </li>
            <li>
              <PasswordRule
                  password={password}
                  label= "Number required"
                  validation= "[0-9]"
              ></PasswordRule>
            </li>
            <li>
              <PasswordRule
                  password={password}
                  label= "Special characters (!@#$%^&*)"
                  validation= "[!@#$%^&*]"
              ></PasswordRule>
            </li>
          </ul>
          
        </div>
    </div>
  );
};

