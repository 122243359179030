import { useState } from 'react';
import { Form, Navigate } from 'react-router-dom';
import { OutlinedTextField, FilledButton, Icon } from '../../../common/components/material';

import styles from './ResetPassword.module.scss';
import {  Action, ActionStatus, Result } from '../../../store';
import { BannerMessage, Text } from '../../../common/components';
import { authenticationService, resetPassword } from '../../../store/slices/authentication';
import { usePageStrings } from '../../../common/hooks';
import { appLogService } from '../../../store/slices/appLog';

export function ResetPassword() {

  const pageStrings = usePageStrings();
  const [rsetPasswordAction, setResetPasswordAction] = useState(Action.idle());
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordChecks, setPasswordChecks] = useState({
    length: false,
    upper: false,
    lower: false,
    number: false,
    special: false,
  });

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    // Update passwordChecks based on the new password value
    setPasswordChecks({
      length: value.length >= 12,
      upper: /[A-Z]/.test(value),
      lower: /[a-z]/.test(value),
      number: /[0-9]/.test(value),
      special: /[!@#$%^&*]/.test(value),
    });
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    const urlObj = new URL(window.location.href);
    const params = new URLSearchParams(urlObj.search);

    const userId = params.get("userId") || "";
    const token = params.get("resetCode") || "";

      // const userId = params.get('userId') || '';
      // const token = params.get('resetCode') || '';
      

      authenticationService.resetPassword({
          userId: userId,
          resetCode: token,
          newPassword: password
      }).then((result: Result<string>) => {
        if (result.isSuccess) {
            setResetPasswordAction(Action.succeeded());
        }
        else {
            setResetPasswordAction(Action.failed(result.errorMessage));
        }
      }).catch((reason) => {
          setErrorMessage(reason.message || 'Failed to reset password');
          setResetPasswordAction(Action.failed(pageStrings.general.error_Unexpected));
          appLogService.fatal('Resetpassword.tsx', 'handleSubmit', 'Send', reason);
      });
  };

  const getColor = (check: boolean) => (check ? "green" : "red");

  return rsetPasswordAction.status === ActionStatus.succeeded ? (
      <Navigate to="/authentication/signin"></Navigate>
) : (
      <div className={styles['ResetPassword']}>
          <Form onSubmit={handleSubmit} className={styles['ResetPassword']}>
              <header>
                  <h1 className={styles['ResetPassword-heading1']}>
                      <Text fontStyle='headline-small' value={pageStrings.resetPasswordPage.heading_resetPassword}></Text>
                  </h1>
              </header>

        {errorMessage && (
          <div className={styles["SignIn-errorMessage"]}>
            <BannerMessage
              messageType="error"
              message={errorMessage}
            ></BannerMessage>
          </div>
        )}

        <div className={styles["ResetPassword-content"]}>
          <OutlinedTextField
            label={pageStrings.resetPasswordPage.formItem_password_label}
            name="password"
            placeholder={pageStrings.resetPasswordPage.formItem_password_placeholder}
            required
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />

          <OutlinedTextField
            label={pageStrings.resetPasswordPage.formItem_confirmPassword_label}
            name="confirmPassword"
            placeholder={pageStrings.resetPasswordPage.formItem_confirmPassword_placeholder}
            required
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />

          <ul>
            <li>
              <span style={{ color: getColor(passwordChecks.length) }}>
                <Icon ariaHidden name="circle" size={18}></Icon>
              </span>
              <Text fontStyle='label-medium' value={pageStrings.resetPasswordPage.text_passwordRequirements_minimumCharacters}></Text>
            </li>
            <li>
              <span style={{ color: getColor(passwordChecks.upper) }}>
                <Icon ariaHidden name="circle" size={18}></Icon>
              </span>
              <Text fontStyle='label-medium' value={pageStrings.resetPasswordPage.text_passwordRequirements_upperCase}></Text>
            </li>
            <li>
              <span style={{ color: getColor(passwordChecks.lower) }}>
                <Icon ariaHidden name="circle" size={18}></Icon>
              </span>
              <Text fontStyle='label-medium' value={pageStrings.resetPasswordPage.text_passwordRequirements_lowerCase}></Text>
            </li>
            <li>
              <span style={{ color: getColor(passwordChecks.number) }}>
                <Icon ariaHidden name="circle" size={18}></Icon>
              </span>
              <Text fontStyle='label-medium' value={pageStrings.resetPasswordPage.text_passwordRequirements_number}></Text>
            </li>
            <li>
              <span style={{ color: getColor(passwordChecks.special) }}>
                <Icon ariaHidden name="circle" size={18}></Icon>
              </span>
              <Text fontStyle='label-medium' value={pageStrings.resetPasswordPage.text_passwordRequirements_specialCharacters}></Text>
            </li>
          </ul>

          <div className={styles["ResetPassword-actions"]}>
            <FilledButton type="submit" value={pageStrings.resetPasswordPage.button_resetPassword_label}></FilledButton>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default ResetPassword;
