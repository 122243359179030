import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WindowSizeType, ILayoutState, IWindowSize } from './layout.types';
import { PolicyName } from "../../../common/hooks/useActionChecker";
import { ActionStatus } from "../../config";


const initialState: ILayoutState = {
    appLayout: {
        isNavigationDrawerOpened: true,
        isProgressVisible: false
    },
    error: null,
    initialLoad: {
        isConfigurationLoaded: false,
        isTranslationFileLoaded: false
    },
    mainContentScrollPosition: 0,
    status: ActionStatus.idle,
    pageTitle: '',
    windowSize: {
        height: 0,
        width: 0
    },
    windowSizeType: WindowSizeType.None,
    // env: {}
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {

        configurationLoaded: (state: ILayoutState) => {
            state.initialLoad.isConfigurationLoaded = true;
            isInitialLoadCompleted(state);
        },

        changePageTitle: (state: ILayoutState, action: PayloadAction<string>) => {
            state.pageTitle = action.payload;
            document.title = state.pageTitle.length > 0 ? `Intellek LMS - ${state.pageTitle}` : 'Intellek LMS';
        },

        hideProgress: (state: ILayoutState) => {
            state.appLayout.isProgressVisible = false;
        },

        mainContentScrollPositionChanged: (state: ILayoutState, action: PayloadAction<number>) => {
            state.mainContentScrollPosition = action.payload;
        },

        navigationIconActivated: (state: ILayoutState) => {
            state.appLayout.isNavigationDrawerOpened = !state.appLayout.isNavigationDrawerOpened;
        },

        setCanAccessPolicy: (state: ILayoutState, action: PayloadAction<PolicyName>) => {
            state.canAccessPolicy = action.payload;
        },

        setErrorMessage: (state: ILayoutState, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.status = ActionStatus.failed;
        },



        // setEnvironmentData: (state: ILayoutState, action: PayloadAction<object>) => {
        //     console.log(action.payload);
        //     state.env = action.payload;
        // },
        showProgress: (state: ILayoutState) => {
            state.appLayout.isProgressVisible = true;
        },

        startInitialLoad: (state: ILayoutState) => {
            state.status = ActionStatus.loading;
        },

        translationFileLoaded: (state: ILayoutState) => {
            state.initialLoad.isTranslationFileLoaded = true;
            isInitialLoadCompleted(state);
        },

        windowSizeChanged: (state: ILayoutState, action: PayloadAction<IWindowSize>) => {
            state.windowSize = action.payload
            state.windowSizeType = getWindowSizeType(action.payload.width);
        },
    }
});

export const {
    changePageTitle,
    configurationLoaded,
    hideProgress,
    mainContentScrollPositionChanged, 
    navigationIconActivated,
    setCanAccessPolicy,
    setErrorMessage,
    showProgress,
    startInitialLoad,
    // setEnvironmentData,
    translationFileLoaded,
    windowSizeChanged
} = layoutSlice.actions;

export default layoutSlice.reducer;

// Private methods
function isInitialLoadCompleted(state: ILayoutState) {

    let isCompleted =
        state.initialLoad.isTranslationFileLoaded &&
        state.initialLoad.isConfigurationLoaded;

    if (isCompleted) state.status = ActionStatus.succeeded;
}

function getWindowSizeType(windowWidth: number): WindowSizeType {

    var windowSizeType: WindowSizeType = WindowSizeType.None;

    if (windowWidth < 600) {

        windowSizeType = WindowSizeType.Compact;

    } else if (600 <= windowWidth && windowWidth < 840) {

        windowSizeType = WindowSizeType.Medium;

    } else if (840 <= windowWidth && windowWidth < 1200) {

        windowSizeType = WindowSizeType.Expanded;

    } else if (1200 <= windowWidth && windowWidth < 1600) {

        windowSizeType = WindowSizeType.Large;

    } else if (1600 <= windowWidth) {

        windowSizeType = WindowSizeType.ExtraLarge;

    }

    return windowSizeType;

}